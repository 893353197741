import { NETWORK_STATUS } from '../constants';

const INIT_STATE = {
  isOnline: window.navigator.onLine,
};

const networkReducer = (state = INIT_STATE, action) => {
  let returnObj = state;
  if(action.type === NETWORK_STATUS){
    returnObj = {
      ...state,
      isOnline: action.data,
    };
  }
  return returnObj;
};

export default networkReducer;
