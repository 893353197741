/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://vpouivo269.execute-api.ap-southeast-2.amazonaws.com/akaysha",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://tiooyqogzbftdaxjiaedw6ltqa.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-southeast-2:4375c5a9-d664-47fe-b57c-67b16050556a",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_cpgwtOWFG",
    "aws_user_pools_web_client_id": "3veea74n5d03onnfn3s53lkmto",
    "oauth": {
        domain: 'sureworx-akaysha.auth.ap-southeast-2.amazoncognito.com',
        client_id: '3veea74n5d03onnfn3s53lkmto',
        scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://akaysha-admin.sureworx.io/',
        redirectSignOut: 'https://akaysha-admin.sureworx.io/auth/login/',
        responseType: 'code'
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "intellitrackstorage84720-akaysha",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
  };
  
  
  export default awsmobile;
