import {
  AUTH_STATE,
  USER_LOGIN,
  LOGOUT,
  TOGGLE_LOADING,
  UPDATE_PASSWORD,
  UPDATE_ROLES,
  UPDATE_TENANT,
  USER_DETAILS,
  UPDATE_ORGANISATION,
  ERROR,
  ASSIGNED_PROJECTS,
  TENANT_CONFIG,
} from '../constants';

const INIT_STATE = {
  data: [],
  session: [],
  isAuth: false,
  isUpdatePassword: false,
  error: null,
  loading: false,
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case USER_LOGIN:
      return {
        ...state,
        data: action.data.attributes,
        session: action.data.signInUserSession,
        isAuth: true,
        loading: false,
      };
    case AUTH_STATE:
      return {
        ...state,
        data: action.data.attributes,
        session: action.data.signInUserSession,
        isAuth: true,
        loading: false,
      };
    case LOGOUT:
      return {
        data: [],
        session: [],
        isAuth: false,
        loading: false,
      };
    // For update roles in auth reducer
    case UPDATE_ROLES:
      return {
        ...state,
        data: { ...state.data, roleData: action.data }
      };

    // For update org in auth reducer
    case UPDATE_ORGANISATION:
      return {
        ...state,
        data: { ...state.data, orgData: action.data }
      };

    // For update org in auth reducer
    case USER_DETAILS:
      return {
        ...state,
        data: { ...state.data, userDetails: action.data }
      };
    // For update Tenant in auth reducer
    case UPDATE_TENANT:
      return {
        ...state,
        data: { ...state.data, tenantDetails: action.data }
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
        isUpdatePassword: action.data,
        loading: false,
      };
    case ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ASSIGNED_PROJECTS:
      return {
        ...state,
        data: { ...state.data, assigned_projects: action.data }
      };
    case TENANT_CONFIG:
      return {
        ...state,
        data: { ...state.data, tenant_config: action.data }
      };
    default:
      return state;
  }
};

export default authReducer;
