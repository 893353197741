import {
  THEME_COLOR,
  NAVBAR_BG,
  SIDEBAR_BG,
  DIRECTION,
  DARK_THEME,
  CONTAINER_LAYOUT,
  SET_LANGUAGE,
} from '../constants';

const activeMode = () => {
  return localStorage.getItem('activeMode') !== null ? localStorage.getItem('activeMode') : 'light';
};

const getContainerLayout = () => {
  return localStorage.getItem('containerLayout') !== null
    ? localStorage.getItem('containerLayout')
    : 'wide';
};

const activeTheme = () => {
  return localStorage.getItem('activeTheme') !== null
    ? localStorage.getItem('activeTheme')
    : 'SUREWORX_THEME';
};

const INIT_STATE = {
  activeDir: 'ltr',
  activeNavbarBg: '#f16050',
  activeSidebarBg: '#ffffff',
  activeMode: activeMode(),
  activeTheme: activeTheme(),
  containerLayout: getContainerLayout(),
  SidebarWidth: 240,
  language: '',
};

const CustomizerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NAVBAR_BG:
      return {
        ...state,
        activeNavbarBg: action.payload,
      };
    case DARK_THEME:
      return {
        ...state,
        activeMode: action.payload,
      };
    case SIDEBAR_BG:
      return {
        ...state,
        activeSidebarBg: action.payload,
      };
    case THEME_COLOR:
      return {
        ...state,
        activeTheme: action.payload,
      };
    case DIRECTION:
      return {
        ...state,
        activeDir: action.payload,
      };
    case CONTAINER_LAYOUT: {
      const containerLayout = state.containerLayout === 'boxed' ? 'wide' : 'boxed';
      localStorage.setItem('containerLayout', containerLayout);

      return {
        ...state,
        containerLayout,
      };
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }

    default:
      return state;
  }
};

export default CustomizerReducer;
