/* eslint-disable */
import { ADD_ASSET_REGISTERS , FETCH_ASSET_REGISTERS, UPDATE_ASSET_REGISTERS, DELETE_ASSET_REGISTERS } from './../constants'


const INIT_STATE = {
  data: [],
};

const assetReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_ASSET_REGISTERS:
      return {
        ...state,
        data: [ ...state?.data, ...action.data],
      };
    case FETCH_ASSET_REGISTERS:
      return {
        ...state,
        data: action.data,
      };
    case UPDATE_ASSET_REGISTERS:
      return {
        ...state,
        data: action.data,
      };
    case DELETE_ASSET_REGISTERS:
      return {
        data: [],
      };
    default:
      return state;
  }
};

export default assetReducer;