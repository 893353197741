export const LANGUAGES = [
  {
    code: 'aa-DJ',
    label: 'Afar (Djibouti)',
    currencySymbol: 'Fdj',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'aa-ER',
    label: 'Afar (Eritrea)',
    currencySymbol: 'Nfk',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'aa-ET',
    label: 'Afar (Ethiopia)',
    currencySymbol: 'Br',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'af-NA',
    label: 'Afrikaans (Namibia) [Afrikaans (Namibië)]',
    currencySymbol: '$',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd dd MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'af-ZA',
    label: 'Afrikaans (South Africa) [Afrikaans (Suid-Afrika)]',
    currencySymbol: 'R',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd dd MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'agq-CM',
    label: 'Aghem (Cameroon) [Aghem (Kàmàlûŋ)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ak-GH',
    label: 'Akan (Ghana) [Akan (Gaana)]',
    currencySymbol: 'GH₵',
    shortDatePattern: 'yyyy/MM/dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, yyyy MMMM dd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'sq-AL',
    label: 'Albanian (Albania) [shqip (Shqipëri)]',
    currencySymbol: 'Lekë',
    shortDatePattern: 'd.M.yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'sq-XK',
    label: 'Albanian (Kosovo) [shqip (Kosovë)]',
    currencySymbol: '€',
    shortDatePattern: 'd.M.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sq-MK',
    label: 'Albanian (North Macedonia) [shqip (Maqedonia e Veriut)]',
    currencySymbol: 'den',
    shortDatePattern: 'd.M.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'am-ET',
    label: 'Amharic (Ethiopia) [አማርኛ (ኢትዮጵያ)]',
    currencySymbol: 'ብር',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-DZ',
    label: 'Arabic (Algeria) [‎العربية (الجزائر)‏]',
    currencySymbol: 'د.ج.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-BH',
    label: 'Arabic (Bahrain) [‎العربية (البحرين)‏]',
    currencySymbol: 'د.ب.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-TD',
    label: 'Arabic (Chad) [‎العربية (تشاد)‏]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-KM',
    label: 'Arabic (Comoros) [‎العربية (جزر القمر)‏]',
    currencySymbol: 'CF',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ar-DJ',
    label: 'Arabic (Djibouti) [‎العربية (جيبوتي)‏]',
    currencySymbol: 'Fdj',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-EG',
    label: 'Arabic (Egypt) [‎العربية (مصر)‏]',
    currencySymbol: 'ج.م.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-ER',
    label: 'Arabic (Eritrea) [‎العربية (إريتريا)‏]',
    currencySymbol: 'Nfk',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-IQ',
    label: 'Arabic (Iraq) [‎العربية (العراق)‏]',
    currencySymbol: 'د.ع.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-IL',
    label: 'Arabic (Israel) [‎العربية (إسرائيل)‏]',
    currencySymbol: '₪',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'ar-JO',
    label: 'Arabic (Jordan) [‎العربية (الأردن)‏]',
    currencySymbol: 'د.أ.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-KW',
    label: 'Arabic (Kuwait) [‎العربية (الكويت)‏]',
    currencySymbol: 'د.ك.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-LB',
    label: 'Arabic (Lebanon) [‎العربية (لبنان)‏]',
    currencySymbol: 'ل.ل.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-LY',
    label: 'Arabic (Libya) [‎العربية (ليبيا)‏]',
    currencySymbol: 'د.ل.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-MR',
    label: 'Arabic (Mauritania) [‎العربية (موريتانيا)‏]',
    currencySymbol: 'أ.م.',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-MA',
    label: 'Arabic (Morocco) [‎العربية (المغرب)‏]',
    currencySymbol: 'د.م.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ar-OM',
    label: 'Arabic (Oman) [‎العربية (عُمان)‏]',
    currencySymbol: 'ر.ع.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-PS',
    label: 'Arabic (Palestinian Authority) [‎العربية (السلطة الفلسطينية)‏]',
    currencySymbol: '₪',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-QA',
    label: 'Arabic (Qatar) [‎العربية (قطر)‏]',
    currencySymbol: 'ر.ق.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-SA',
    label: 'Arabic (Saudi Arabia) [‎العربية (المملكة العربية السعودية)‏]',
    currencySymbol: 'ر.س.‏',
    shortDatePattern: 'd‏/M‏/yyyy g',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy g',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-SO',
    label: 'Arabic (Somalia) [‎العربية (الصومال)‏]',
    currencySymbol: 'S',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-SS',
    label: 'Arabic (South Sudan) [‎العربية (جنوب السودان)‏]',
    currencySymbol: '£',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-SD',
    label: 'Arabic (Sudan) [‎العربية (السودان)‏]',
    currencySymbol: 'ج.س.',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-SY',
    label: 'Arabic (Syria) [‎العربية (سوريا)‏]',
    currencySymbol: 'ل.س.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-TN',
    label: 'Arabic (Tunisia) [‎العربية (تونس)‏]',
    currencySymbol: 'د.ت.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-AE',
    label: 'Arabic (United Arab Emirates) [‎العربية (الإمارات العربية المتحدة)‏]',
    currencySymbol: 'د.إ.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ar-YE',
    label: 'Arabic (Yemen) [‎العربية (اليمن)‏]',
    currencySymbol: 'ر.ي.‏',
    shortDatePattern: 'd‏/M‏/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'hy-AM',
    label: 'Armenian (Armenia) [հայերեն (Հայաստան)]',
    currencySymbol: '֏',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy թ. MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'as-IN',
    label: 'Assamese (India) [অসমীয়া (ভাৰত)]',
    currencySymbol: '₹',
    shortDatePattern: 'd-M-yyyy',
    shortTimePattern: 'tt h.mm',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'tt h.mm.ss',
    supported: false,
  },
  {
    code: 'ast-ES',
    label: 'Asturian (Spain) [asturianu (España)]',
    currencySymbol: '€',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'asa-TZ',
    label: 'Asu (Tanzania) [Kipare (Tadhania)]',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'az-Cyrl-AZ',
    label: 'Azerbaijani (Cyrillic, Azerbaijan) [азәрбајҹан (Кирил, Азәрбајҹан)]',
    currencySymbol: '₼',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'd MMMM yyyy, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'az-Latn-AZ',
    label: 'Azerbaijani (Latin, Azerbaijan) [azərbaycan (latın, Azərbaycan)]',
    currencySymbol: '₼',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'd MMMM yyyy, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ksf-CM',
    label: 'Bafia (Cameroon) [rikpa (kamɛrún)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'bm-ML',
    label: 'Bamanankan (Mali) [bamanakan (Mali)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'bn-BD',
    label: 'Bangla (Bangladesh) [বাংলা (বাংলাদেশ)]',
    currencySymbol: '৳',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'bn-IN',
    label: 'Bangla (India) [বাংলা (ভারত)]',
    currencySymbol: '₹',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'bas-CM',
    label: 'Basaa (Cameroon) [Ɓàsàa (Kàmɛ̀rûn)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ba-RU',
    label: 'Bashkir (Russia)',
    currencySymbol: 'RUB',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'eu-ES',
    label: 'Basque (Spain) [euskara (Espainia)]',
    currencySymbol: '€',
    shortDatePattern: 'yyyy/M/d',
    shortTimePattern: 'HH:mm',
    longDatePattern: "yyyy('e')'ko' MMMM'ren' d('a'), dddd",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'be-BY',
    label: 'Belarusian (Belarus) [беларуская (Беларусь)]',
    currencySymbol: 'Br',
    shortDatePattern: 'd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d MMMM yyyy 'г'.",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'bem-ZM',
    label: 'Bemba (Zambia) [Ichibemba (Zambia)]',
    currencySymbol: 'K',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'bez-TZ',
    label: 'Bena (Tanzania) [Hibena (Hutanzania)]',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'byn-ER',
    label: 'Blin (Eritrea)',
    currencySymbol: 'Nfk',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'brx-IN',
    label: 'Bodo (India) [बड़ो (भारत)]',
    currencySymbol: '₹',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'bs-Cyrl-BA',
    label: 'Bosnian (Cyrillic, Bosnia & Herzegovina) [босански (ћирилица, Босна и Херцеговина)]',
    currencySymbol: 'КМ',
    shortDatePattern: 'd.M.yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'bs-Latn-BA',
    label: 'Bosnian (Latin, Bosnia & Herzegovina) [bosanski (latinica, Bosna i Hercegovina)]',
    currencySymbol: 'KM',
    shortDatePattern: 'd. M. yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'br-FR',
    label: 'Breton (France) [brezhoneg (Frañs)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'bg-BG',
    label: 'Bulgarian (Bulgaria) [български (България)]',
    currencySymbol: 'лв.',
    shortDatePattern: "d.MM.yyyy 'г'.",
    shortTimePattern: "H:mm 'ч'.",
    longDatePattern: "dddd, d MMMM yyyy 'г'.",
    longTimePattern: "H:mm:ss 'ч'.",
    supported: false,
  },
  {
    code: 'my-MM',
    label: 'Burmese (Myanmar) [မြန်မာ (မြန်မာ)]',
    currencySymbol: 'K',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: ' H:mm',
    longDatePattern: 'yyyy၊ MMMM d၊ dddd',
    longTimePattern: ' HH:mm:ss',
    supported: false,
  },
  {
    code: 'ca-AD',
    label: 'Catalan (Andorra) [català (Andorra)]',
    currencySymbol: '€',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: "dddd, d MMMM 'de' yyyy",
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'ca-FR',
    label: 'Catalan (France) [català (França)]',
    currencySymbol: '€',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: "dddd, d MMMM 'de' yyyy",
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'ca-IT',
    label: 'Catalan (Italy) [català (Itàlia)]',
    currencySymbol: '€',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: "dddd, d MMMM 'de' yyyy",
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'ca-ES-VALENCIA',
    label: 'Catalan (Spain, Valencian) [valencià (Espanya, valencià)]',
    currencySymbol: '€',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: "dddd, d MMMM 'de' yyyy",
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'ca-ES',
    label: 'Catalan (Spain) [català (Espanya)]',
    currencySymbol: '€',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: "dddd, d MMMM 'de' yyyy",
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'ceb-PH',
    label: 'Cebuano (Philippines) [Cebuano (Pilipinas)]',
    currencySymbol: '₱',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'tzm-MA',
    label: 'Central Atlas Tamazight (Morocco) [Tamaziɣt n laṭlaṣ (Meṛṛuk)]',
    currencySymbol: 'MAD',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ckb-IR',
    label: 'Central Kurdish (Iran) [‎کوردیی ناوەندی (ئێران)‏]',
    currencySymbol: 'IRR',
    shortDatePattern: 'g yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'g yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ckb-IQ',
    label: 'Central Kurdish (Iraq) [‎کوردیی ناوەندی (عێراق)‏]',
    currencySymbol: 'د.ع.‏',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ce-RU',
    label: 'Chechen (Russia) [нохчийн (Росси)]',
    currencySymbol: '₽',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'chr-US',
    label: 'Cherokee (United States) [ᏣᎳᎩ (ᏌᏊ ᎢᏳᎾᎵᏍᏔᏅ ᏍᎦᏚᎩ)]',
    currencySymbol: '$',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'cgg-UG',
    label: 'Chiga (Uganda) [Rukiga (Uganda)]',
    currencySymbol: 'USh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'zh-Hans-CN',
    label: 'Chinese (Simplified, China) [中文（简体，中国）]',
    currencySymbol: '￥',
    shortDatePattern: 'yyyy/M/d',
    shortTimePattern: 'tth:mm',
    longDatePattern: 'yyyy年M月d日dddd',
    longTimePattern: 'tth:mm:ss',
    supported: false,
  },
  {
    code: 'zh-Hans-HK',
    label: 'Chinese (Simplified, Hong Kong SAR) [中文（简体，香港特别行政区）]',
    currencySymbol: 'HK$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'tth:mm',
    longDatePattern: 'yyyy年M月d日dddd',
    longTimePattern: 'tth:mm:ss',
    supported: false,
  },
  {
    code: 'zh-Hans-MO',
    label: 'Chinese (Simplified, Macao SAR) [中文（简体，澳门特别行政区）]',
    currencySymbol: 'MOP$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'tth:mm',
    longDatePattern: 'yyyy年M月d日dddd',
    longTimePattern: 'tth:mm:ss',
    supported: false,
  },
  {
    code: 'zh-Hans-SG',
    label: 'Chinese (Simplified, Singapore) [中文（简体，新加坡）]',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'tth:mm',
    longDatePattern: 'yyyy年M月d日dddd',
    longTimePattern: 'tth:mm:ss',
    supported: false,
  },
  {
    code: 'zh-Hant-HK',
    label: 'Chinese (Traditional, Hong Kong SAR) [中文（繁體字，香港特別行政區）]',
    currencySymbol: 'HK$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'tth:mm',
    longDatePattern: 'yyyy年M月d日dddd',
    longTimePattern: 'tth:mm:ss',
    supported: false,
  },
  {
    code: 'zh-Hant-MO',
    label: 'Chinese (Traditional, Macao SAR) [中文（繁體字，澳門特別行政區）]',
    currencySymbol: 'MOP$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'tth:mm',
    longDatePattern: 'yyyy年M月d日dddd',
    longTimePattern: 'tth:mm:ss',
    supported: false,
  },
  {
    code: 'zh-Hant-TW',
    label: 'Chinese (Traditional, Taiwan) [中文（繁體，台灣）]',
    currencySymbol: '$',
    shortDatePattern: 'yyyy/M/d',
    shortTimePattern: 'tth:mm',
    longDatePattern: 'yyyy年M月d日 dddd',
    longTimePattern: 'tth:mm:ss',
    supported: false,
  },
  {
    code: 'cu-RU',
    label: 'Church Slavic (Russia)',
    currencySymbol: '₽',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ksh-DE',
    label: 'Colognian (Germany) [Kölsch en Doütschland]',
    currencySymbol: '€',
    shortDatePattern: 'd. M. yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, 'dä' d. MMMM yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'kw-GB',
    label: 'Cornish (United Kingdom) [kernewek (Rywvaneth Unys)]',
    currencySymbol: '£',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'co-FR',
    label: 'Corsican (France)',
    currencySymbol: '€',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'hr-BA',
    label: 'Croatian (Bosnia & Herzegovina) [hrvatski (Bosna i Hercegovina)]',
    currencySymbol: 'KM',
    shortDatePattern: 'd. M. yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'hr-HR',
    label: 'Croatian (Croatia) [hrvatski (Hrvatska)]',
    currencySymbol: 'HRK',
    shortDatePattern: 'dd. MM. yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'cs-CZ',
    label: 'Czech (Czechia) [čeština (Česko)]',
    currencySymbol: 'Kč',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: 'dddd d. MMMM yyyy',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'da-DK',
    label: 'Danish (Denmark) [dansk (Danmark)]',
    currencySymbol: 'kr.',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH.mm',
    longDatePattern: "dddd 'den' d. MMMM yyyy",
    longTimePattern: 'HH.mm.ss',
    supported: false,
  },
  {
    code: 'da-GL',
    label: 'Danish (Greenland) [dansk (Grønland)]',
    currencySymbol: 'kr.',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH.mm',
    longDatePattern: "dddd 'den' d. MMMM yyyy",
    longTimePattern: 'HH.mm.ss',
    supported: false,
  },
  {
    code: 'dv-MV',
    label: 'Divehi (Maldives)',
    currencySymbol: 'ރ.',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'dua-CM',
    label: 'Duala (Cameroon) [duálá (Cameroun)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nl-AW',
    label: 'Dutch (Aruba) [Nederlands (Aruba)]',
    currencySymbol: 'Afl.',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nl-BE',
    label: 'Dutch (Belgium) [Nederlands (België)]',
    currencySymbol: '€',
    shortDatePattern: 'd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nl-BQ',
    label:
      'Dutch (Bonaire, Sint Eustatius and Saba) [Nederlands (Bonaire, Sint Eustatius en Saba)]',
    currencySymbol: '$',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nl-CW',
    label: 'Dutch (Curaçao) [Nederlands (Curaçao)]',
    currencySymbol: 'NAf.',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nl-NL',
    label: 'Dutch (Netherlands) [Nederlands (Nederland)]',
    currencySymbol: '€',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nl-SX',
    label: 'Dutch (Sint Maarten) [Nederlands (Sint-Maarten)]',
    currencySymbol: 'NAf.',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nl-SR',
    label: 'Dutch (Suriname) [Nederlands (Suriname)]',
    currencySymbol: '$',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'dz-BT',
    label: 'Dzongkha (Bhutan) [རྫོང་ཁ། (འབྲུག།)]',
    currencySymbol: 'Nu.',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: ' h  mm tt',
    longDatePattern: 'dddd, སྤྱི་ལོ་yyyy MMMM ཚེས་dd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ebu-KE',
    label: 'Embu (Kenya) [Kĩembu (Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-AS',
    label: 'English (American Samoa)',
    currencySymbol: '$',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-AI',
    label: 'English (Anguilla)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-AG',
    label: 'English (Antigua & Barbuda)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-AU',
    label: 'English (Australia)',
    currencySymbol: '$',
    extraShortDatePattern: 'DD/MM',
    // extraShortDatePattern: 'MM/DD',
    shortDatePattern: 'DD/MM/yyyy',
    // shortDatePattern: 'MM/DD/yyyy',
    shortTimePattern: 'h:mm A',
    longDatePattern: 'dddd, D MMMM yyyy',
    longTimePattern: 'h:mm:ss A',
    default: true,
    supported: true,
  },
  {
    code: 'en-AT',
    label: 'English (Austria)',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-BS',
    label: 'English (Bahamas)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-BB',
    label: 'English (Barbados)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-BE',
    label: 'English (Belgium)',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-BZ',
    label: 'English (Belize)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-BM',
    label: 'English (Bermuda)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-BW',
    label: 'English (Botswana)',
    currencySymbol: 'P',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-IO',
    label: 'English (British Indian Ocean Territory)',
    currencySymbol: 'US$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-VG',
    label: 'English (British Virgin Islands)',
    currencySymbol: 'US$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-BI',
    label: 'English (Burundi)',
    currencySymbol: 'FBu',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-CM',
    label: 'English (Cameroon)',
    currencySymbol: 'FCFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-CA',
    label: 'English (Canada)',
    currencySymbol: '$',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-KY',
    label: 'English (Cayman Islands)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-CX',
    label: 'English (Christmas Island)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-CC',
    label: 'English (Cocos [Keeling] Islands)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-CK',
    label: 'English (Cook Islands)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-CY',
    label: 'English (Cyprus)',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-DK',
    label: 'English (Denmark)',
    currencySymbol: 'kr.',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH.mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH.mm.ss',
    supported: false,
  },
  {
    code: 'en-DM',
    label: 'English (Dominica)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-ER',
    label: 'English (Eritrea)',
    currencySymbol: 'Nfk',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-SZ',
    label: 'English (Eswatini)',
    currencySymbol: 'E',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-FK',
    label: 'English (Falkland Islands)',
    currencySymbol: '£',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-FJ',
    label: 'English (Fiji)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-FI',
    label: 'English (Finland)',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'H.mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'H.mm.ss',
    supported: false,
  },
  {
    code: 'en-GM',
    label: 'English (Gambia)',
    currencySymbol: 'D',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-DE',
    label: 'English (Germany)',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-GH',
    label: 'English (Ghana)',
    currencySymbol: 'GH₵',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-GI',
    label: 'English (Gibraltar)',
    currencySymbol: '£',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-GD',
    label: 'English (Grenada)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-GU',
    label: 'English (Guam)',
    currencySymbol: '$',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-GG',
    label: 'English (Guernsey)',
    currencySymbol: '£',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-GY',
    label: 'English (Guyana)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-HK',
    label: 'English (Hong Kong SAR)',
    currencySymbol: 'HK$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-IN',
    label: 'English (India)',
    currencySymbol: '₹',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-IE',
    label: 'English (Ireland)',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-IM',
    label: 'English (Isle of Man)',
    currencySymbol: '£',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-IL',
    label: 'English (Israel)',
    currencySymbol: '₪',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'en-JM',
    label: 'English (Jamaica)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-JE',
    label: 'English (Jersey)',
    currencySymbol: '£',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-KE',
    label: 'English (Kenya)',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-KI',
    label: 'English (Kiribati)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-LS',
    label: 'English (Lesotho)',
    currencySymbol: 'R',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-LR',
    label: 'English (Liberia)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-MO',
    label: 'English (Macao SAR)',
    currencySymbol: 'MOP$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-MG',
    label: 'English (Madagascar)',
    currencySymbol: 'Ar',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-MW',
    label: 'English (Malawi)',
    currencySymbol: 'MK',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-MY',
    label: 'English (Malaysia)',
    currencySymbol: 'RM',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-MT',
    label: 'English (Malta)',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-MH',
    label: 'English (Marshall Islands)',
    currencySymbol: '$',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-MU',
    label: 'English (Mauritius)',
    currencySymbol: 'Rs',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-FM',
    label: 'English (Micronesia)',
    currencySymbol: 'US$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-MS',
    label: 'English (Montserrat)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-NA',
    label: 'English (Namibia)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-NR',
    label: 'English (Nauru)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-NL',
    label: 'English (Netherlands)',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-NZ',
    label: 'English (New Zealand)',
    currencySymbol: '$',
    shortDatePattern: 'd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-NG',
    label: 'English (Nigeria)',
    currencySymbol: '₦',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-NU',
    label: 'English (Niue)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-NF',
    label: 'English (Norfolk Island)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-MP',
    label: 'English (Northern Mariana Islands)',
    currencySymbol: '$',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-PK',
    label: 'English (Pakistan)',
    currencySymbol: 'Rs',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-PW',
    label: 'English (Palau)',
    currencySymbol: 'US$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-PG',
    label: 'English (Papua New Guinea)',
    currencySymbol: 'K',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-PH',
    label: 'English (Philippines)',
    currencySymbol: '₱',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-PN',
    label: 'English (Pitcairn Islands)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-PR',
    label: 'English (Puerto Rico)',
    currencySymbol: '$',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-RW',
    label: 'English (Rwanda)',
    currencySymbol: 'RF',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-WS',
    label: 'English (Samoa)',
    currencySymbol: 'WS$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-SC',
    label: 'English (Seychelles)',
    currencySymbol: 'SR',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-SL',
    label: 'English (Sierra Leone)',
    currencySymbol: 'Le',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-SG',
    label: 'English (Singapore)',
    currencySymbol: '$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-SX',
    label: 'English (Sint Maarten)',
    currencySymbol: 'NAf.',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-SI',
    label: 'English (Slovenia)',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-SB',
    label: 'English (Solomon Islands)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-ZA',
    label: 'English (South Africa)',
    currencySymbol: 'R',
    shortDatePattern: 'yyyy/MM/dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-SS',
    label: 'English (South Sudan)',
    currencySymbol: '£',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-SH',
    label: 'English (St Helena, Ascension, Tristan da Cunha)',
    currencySymbol: '£',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-KN',
    label: 'English (St. Kitts & Nevis)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-LC',
    label: 'English (St. Lucia)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-VC',
    label: 'English (St. Vincent & Grenadines)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-SD',
    label: 'English (Sudan)',
    currencySymbol: 'SDG',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-SE',
    label: 'English (Sweden)',
    currencySymbol: 'kr',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-CH',
    label: 'English (Switzerland)',
    currencySymbol: 'CHF',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-TZ',
    label: 'English (Tanzania)',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-TK',
    label: 'English (Tokelau)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-TO',
    label: 'English (Tonga)',
    currencySymbol: 'T$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-TT',
    label: 'English (Trinidad & Tobago)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-TC',
    label: 'English (Turks & Caicos Islands)',
    currencySymbol: 'US$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-TV',
    label: 'English (Tuvalu)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-VI',
    label: 'English (U.S. Virgin Islands)',
    currencySymbol: '$',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-UG',
    label: 'English (Uganda)',
    currencySymbol: 'USh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-AE',
    label: 'English (United Arab Emirates)',
    currencySymbol: 'AED',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-GB',
    label: 'English (United Kingdom)',
    currencySymbol: '£',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'en-US',
    label: 'English (United States)',
    currencySymbol: '$',
    extraShortDatePattern: 'M/D',
    shortDatePattern: 'M/D/yyyy',
    shortTimePattern: 'h:mm A',
    longDatePattern: 'dddd, MMMM D, yyyy',
    longTimePattern: 'h:mm:ss A',
    supported: true,
  },
  {
    code: 'en-VU',
    label: 'English (Vanuatu)',
    currencySymbol: 'VT',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-ZM',
    label: 'English (Zambia)',
    currencySymbol: 'K',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'en-ZW',
    label: 'English (Zimbabwe)',
    currencySymbol: 'US$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'et-EE',
    label: 'Estonian (Estonia) [eesti (Eesti)]',
    currencySymbol: '€',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ee-GH',
    label: 'Ewe (Ghana) [Eʋegbe (Ghana nutome)]',
    currencySymbol: 'GH₵',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: "tt 'ga' h:mm",
    longDatePattern: "dddd, MMMM d 'lia' yyyy",
    longTimePattern: "tt 'ga' h:mm:ss",
    supported: false,
  },
  {
    code: 'ee-TG',
    label: 'Ewe (Togo) [Eʋegbe (Togo nutome)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, MMMM d 'lia' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ewo-CM',
    label: 'Ewondo (Cameroon) [ewondo (Kamərún)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fo-DK',
    label: 'Faroese (Denmark) [føroyskt (Danmark)]',
    currencySymbol: 'kr.',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fo-FO',
    label: 'Faroese (Faroe Islands) [føroyskt (Føroyar)]',
    currencySymbol: 'kr',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fil-PH',
    label: 'Filipino (Philippines) [Filipino (Pilipinas)]',
    currencySymbol: '₱',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'fi-FI',
    label: 'Finnish (Finland) [suomi (Suomi)]',
    currencySymbol: '€',
    shortDatePattern: 'd.M.yyyy',
    shortTimePattern: 'H.mm',
    longDatePattern: 'dddd d. MMMM yyyy',
    longTimePattern: 'H.mm.ss',
    supported: false,
  },
  {
    code: 'fr-DZ',
    label: 'French (Algeria) [français (Algérie)]',
    currencySymbol: 'DA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'fr-BE',
    label: 'French (Belgium) [français (Belgique)]',
    currencySymbol: '€',
    shortDatePattern: 'd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-BJ',
    label: 'French (Benin) [français (Bénin)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-BF',
    label: 'French (Burkina Faso) [français (Burkina Faso)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-BI',
    label: 'French (Burundi) [français (Burundi)]',
    currencySymbol: 'FBu',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-CM',
    label: 'French (Cameroon) [français (Cameroun)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-CA',
    label: 'French (Canada) [français (Canada)]',
    currencySymbol: '$',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: "HH 'h' mm",
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: "HH 'h' mm 'min' ss 's'",
    supported: false,
  },
  {
    code: 'fr-CF',
    label: 'French (Central African Republic) [français (République centrafricaine)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-TD',
    label: 'French (Chad) [français (Tchad)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'fr-KM',
    label: 'French (Comoros) [français (Comores)]',
    currencySymbol: 'CF',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-CD',
    label: 'French (Congo [DRC]) [français (Congo [République démocratique du])]',
    currencySymbol: 'FC',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-CG',
    label: 'French (Congo) [français (Congo)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-CI',
    label: 'French (Côte d’Ivoire) [français (Côte d’Ivoire)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-DJ',
    label: 'French (Djibouti) [français (Djibouti)]',
    currencySymbol: 'Fdj',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'fr-GQ',
    label: 'French (Equatorial Guinea) [français (Guinée équatoriale)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-FR',
    label: 'French (France) [français (France)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-GF',
    label: 'French (French Guiana) [français (Guyane française)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-PF',
    label: 'French (French Polynesia) [français (Polynésie française)]',
    currencySymbol: 'FCFP',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-GA',
    label: 'French (Gabon) [français (Gabon)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-GP',
    label: 'French (Guadeloupe) [français (Guadeloupe)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-GN',
    label: 'French (Guinea) [français (Guinée)]',
    currencySymbol: 'FG',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-HT',
    label: 'French (Haiti) [français (Haïti)]',
    currencySymbol: 'G',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-LU',
    label: 'French (Luxembourg) [français (Luxembourg)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-MG',
    label: 'French (Madagascar) [français (Madagascar)]',
    currencySymbol: 'Ar',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-ML',
    label: 'French (Mali) [français (Mali)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-MQ',
    label: 'French (Martinique) [français (Martinique)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-MR',
    label: 'French (Mauritania) [français (Mauritanie)]',
    currencySymbol: 'UM',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'fr-MU',
    label: 'French (Mauritius) [français (Maurice)]',
    currencySymbol: 'Rs',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-YT',
    label: 'French (Mayotte) [français (Mayotte)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-MC',
    label: 'French (Monaco) [français (Monaco)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-MA',
    label: 'French (Morocco) [français (Maroc)]',
    currencySymbol: 'MAD',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-NC',
    label: 'French (New Caledonia) [français (Nouvelle-Calédonie)]',
    currencySymbol: 'FCFP',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-NE',
    label: 'French (Niger) [français (Niger)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-RE',
    label: 'French (Réunion) [français (La Réunion)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-RW',
    label: 'French (Rwanda) [français (Rwanda)]',
    currencySymbol: 'RF',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-SN',
    label: 'French (Senegal) [français (Sénégal)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-SC',
    label: 'French (Seychelles) [français (Seychelles)]',
    currencySymbol: 'SR',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-BL',
    label: 'French (St. Barthélemy) [français (Saint-Barthélemy)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-MF',
    label: 'French (St. Martin) [français (Saint-Martin)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-PM',
    label: 'French (St. Pierre & Miquelon) [français (Saint-Pierre-et-Miquelon)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-CH',
    label: 'French (Switzerland) [français (Suisse)]',
    currencySymbol: 'CHF',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-SY',
    label: 'French (Syria) [français (Syrie)]',
    currencySymbol: 'LS',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'fr-TG',
    label: 'French (Togo) [français (Togo)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fr-TN',
    label: 'French (Tunisia) [français (Tunisie)]',
    currencySymbol: 'DT',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'fr-VU',
    label: 'French (Vanuatu) [français (Vanuatu)]',
    currencySymbol: 'VT',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'fr-WF',
    label: 'French (Wallis & Futuna) [français (Wallis-et-Futuna)]',
    currencySymbol: 'FCFP',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fur-IT',
    label: 'Friulian (Italy) [furlan (Italie)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd d 'di' MMMM 'dal' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ff-Latn-BF',
    label: 'Fulah (Latin, Burkina Faso)',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ff-Latn-CM',
    label: 'Fulah (Latin, Cameroon)',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ff-Latn-GM',
    label: 'Fulah (Latin, Gambia)',
    currencySymbol: 'D',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ff-Latn-GH',
    label: 'Fulah (Latin, Ghana)',
    currencySymbol: 'GH₵',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ff-Latn-GW',
    label: 'Fulah (Latin, Guinea-Bissau)',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ff-Latn-GN',
    label: 'Fulah (Latin, Guinea)',
    currencySymbol: 'FG',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ff-Latn-LR',
    label: 'Fulah (Latin, Liberia)',
    currencySymbol: '$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ff-Latn-MR',
    label: 'Fulah (Latin, Mauritania)',
    currencySymbol: 'UM',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ff-Latn-NE',
    label: 'Fulah (Latin, Niger)',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ff-Latn-NG',
    label: 'Fulah (Latin, Nigeria)',
    currencySymbol: '₦',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ff-Latn-SN',
    label: 'Fulah (Latin, Senegal)',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ff-Latn-SL',
    label: 'Fulah (Latin, Sierra Leone)',
    currencySymbol: 'Le',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'gl-ES',
    label: 'Galician (Spain) [galego (España)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'lg-UG',
    label: 'Ganda (Uganda) [Luganda (Yuganda)]',
    currencySymbol: 'USh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ka-GE',
    label: 'Georgian (Georgia) [ქართული (საქართველო)]',
    currencySymbol: '₾',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd MMMM, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'de-AT',
    label: 'German (Austria) [Deutsch (Österreich)]',
    currencySymbol: '€',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'de-BE',
    label: 'German (Belgium) [Deutsch (Belgien)]',
    currencySymbol: '€',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'de-DE',
    label: 'German (Germany) [Deutsch (Deutschland)]',
    currencySymbol: '€',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'de-IT',
    label: 'German (Italy) [Deutsch (Italien)]',
    currencySymbol: '€',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'de-LI',
    label: 'German (Liechtenstein) [Deutsch (Liechtenstein)]',
    currencySymbol: 'CHF',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'de-LU',
    label: 'German (Luxembourg) [Deutsch (Luxemburg)]',
    currencySymbol: '€',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'de-CH',
    label: 'German (Switzerland) [Deutsch (Schweiz)]',
    currencySymbol: 'CHF',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'el-CY',
    label: 'Greek (Cyprus) [Ελληνικά (Κύπρος)]',
    currencySymbol: '€',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'el-GR',
    label: 'Greek (Greece) [Ελληνικά (Ελλάδα)]',
    currencySymbol: '€',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'gn-PY',
    label: 'Guarani (Paraguay)',
    currencySymbol: '₲',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'gu-IN',
    label: 'Gujarati (India) [ગુજરાતી (ભારત)]',
    currencySymbol: '₹',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'hh:mm tt',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'hh:mm:ss tt',
    supported: false,
  },
  {
    code: 'guz-KE',
    label: 'Gusii (Kenya) [Ekegusii (Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ha-GH',
    label: 'Hausa (Ghana) [Hausa (Gana)]',
    currencySymbol: 'GH₵',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ha-NE',
    label: 'Hausa (Niger) [Hausa (Nijar)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ha-NG',
    label: 'Hausa (Nigeria) [Hausa (Najeriya)]',
    currencySymbol: '₦',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'haw-US',
    label: 'Hawaiian (United States) [ʻŌlelo Hawaiʻi (ʻAmelika Hui Pū ʻIa)]',
    currencySymbol: '$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'he-IL',
    label: 'Hebrew (Israel) [‎עברית (ישראל)‏]',
    currencySymbol: '₪',
    shortDatePattern: 'd.M.yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: 'dddd, d בMMMM yyyy',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'hi-IN',
    label: 'Hindi (India) [हिन्दी (भारत)]',
    currencySymbol: '₹',
    shortDatePattern: 'd MMMM yyyy',
    shortTimePattern: 'h:mm A',
    longDatePattern: 'dddd/MMMM/yyyy',
    longTimePattern: 'hh:mm:ss a',
    supported: false,
  },
  {
    code: 'hu-HU',
    label: 'Hungarian (Hungary) [magyar (Magyarország)]',
    currencySymbol: 'Ft',
    shortDatePattern: 'yyyy. MM. dd.',
    shortTimePattern: 'H:mm',
    longDatePattern: 'yyyy. MMMM d., dddd',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'is-IS',
    label: 'Icelandic (Iceland) [íslenska (Ísland)]',
    currencySymbol: 'ISK',
    shortDatePattern: 'd.M.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ig-NG',
    label: 'Igbo (Nigeria) [Asụsụ Igbo (Naịjịrịa)]',
    currencySymbol: '₦',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'smn-FI',
    label: 'Inari Sami (Finland) [anarâškielâ (Suomâ)]',
    currencySymbol: '€',
    shortDatePattern: 'd.M.yyyy',
    shortTimePattern: 'H.mm',
    longDatePattern: 'dddd, MMMM d. yyyy',
    longTimePattern: 'H.mm.ss',
    supported: false,
  },
  {
    code: 'id-ID',
    label: 'Indonesian (Indonesia) [Indonesia (Indonesia)]',
    currencySymbol: 'Rp',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH.mm',
    longDatePattern: 'dddd, dd MMMM yyyy',
    longTimePattern: 'HH.mm.ss',
    supported: false,
  },
  {
    code: 'iu-CA',
    label: 'Inuktitut (Canada)',
    currencySymbol: 'CA$',
    shortDatePattern: 'MM/dd/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'iu-Latn-CA',
    label: 'Inuktitut (Latin, Canada)',
    currencySymbol: 'CA$',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ga-IE',
    label: 'Irish (Ireland) [Gaeilge (Éire)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'xh-ZA',
    label: 'isiXhosa (South Africa) [isiXhosa (eMzantsi Afrika)]',
    currencySymbol: 'R',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'zu-ZA',
    label: 'isiZulu (South Africa) [isiZulu (iNingizimu Afrika)]',
    currencySymbol: 'R',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'it-IT',
    label: 'Italian (Italy) [italiano (Italia)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'it-SM',
    label: 'Italian (San Marino) [italiano (San Marino)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'it-CH',
    label: 'Italian (Switzerland) [italiano (Svizzera)]',
    currencySymbol: 'CHF',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'it-VA',
    label: 'Italian (Vatican City) [italiano (Città del Vaticano)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ja-JP',
    label: 'Japanese (Japan) [日本語 (日本)]',
    currencySymbol: '￥',
    shortDatePattern: 'yyyy/MM/dd',
    shortTimePattern: 'H:mm',
    longDatePattern: 'yyyy年M月d日dddd',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'jv-ID',
    label: 'Javanese (Indonesia) [Jawa (Indonésia)]',
    currencySymbol: 'Rp',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'dyo-SN',
    label: 'Jola-Fonyi (Senegal) [joola (Senegal)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'kea-CV',
    label: 'Kabuverdianu (Cabo Verde) [kabuverdianu (Kabu Verdi)]',
    currencySymbol: '​',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'di' MMMM 'di' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'kab-DZ',
    label: 'Kabyle (Algeria) [Taqbaylit (Lezzayer)]',
    currencySymbol: 'DA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'kkj-CM',
    label: 'Kako (Cameroon) [kakɔ (Kamɛrun)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'dd/MM yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd dd MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'kl-GL',
    label: 'Kalaallisut (Greenland) [kalaallisut (Kalaallit Nunaat)]',
    currencySymbol: 'kr.',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH.mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH.mm.ss',
    supported: false,
  },
  {
    code: 'kln-KE',
    label: 'Kalenjin (Kenya) [Kalenjin (Emetab Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'kam-KE',
    label: 'Kamba (Kenya) [Kikamba (Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'kn-IN',
    label: 'Kannada (India) [ಕನ್ನಡ (ಭಾರತ)]',
    currencySymbol: '₹',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'hh:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'hh:mm:ss tt',
    supported: false,
  },
  {
    code: 'ks-IN',
    label: 'Kashmiri (India) [‎کٲشُر (ہِندوستان)‏]',
    currencySymbol: '₹',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'kk-KZ',
    label: 'Kazakh (Kazakhstan) [қазақ тілі (Қазақстан)]',
    currencySymbol: '₸',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "yyyy 'ж'. d MMMM, dddd",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'km-KH',
    label: 'Khmer (Cambodia) [ខ្មែរ (កម្ពុជា)]',
    currencySymbol: '៛',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ki-KE',
    label: 'Kikuyu (Kenya) [Gikuyu (Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'rw-RW',
    label: 'Kinyarwanda (Rwanda) [Kinyarwanda (U Rwanda)]',
    currencySymbol: 'RF',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sw-CD',
    label: 'Kiswahili (Congo [DRC]) [Kiswahili (Jamhuri ya Kidemokrasia ya Kongo)]',
    currencySymbol: 'FC',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sw-KE',
    label: 'Kiswahili (Kenya)',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sw-TZ',
    label: 'Kiswahili (Tanzania)',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sw-UG',
    label: 'Kiswahili (Uganda)',
    currencySymbol: 'USh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'kok-IN',
    label: 'Konkani (India) [कोंकणी (भारत)]',
    currencySymbol: '₹',
    shortDatePattern: 'd-M-yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ko-KR',
    label: 'Korean (Korea) [한국어(대한민국)]',
    currencySymbol: '₩',
    shortDatePattern: 'yyyy. M. d.',
    shortTimePattern: 'tt h:mm',
    longDatePattern: 'yyyy년 M월 d일 dddd',
    longTimePattern: 'tt h:mm:ss',
    supported: false,
  },
  {
    code: 'ko-KP',
    label: 'Korean (North Korea) [한국어(조선민주주의인민공화국)]',
    currencySymbol: 'KPW',
    shortDatePattern: 'yyyy. M. d.',
    shortTimePattern: 'tt h:mm',
    longDatePattern: 'yyyy년 M월 d일 dddd',
    longTimePattern: 'tt h:mm:ss',
    supported: false,
  },
  {
    code: 'khq-ML',
    label: 'Koyra Chiini (Mali) [Koyra ciini (Maali)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ses-ML',
    label: 'Koyraboro Senni (Mali) [Koyraboro senni (Maali)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nmg-CM',
    label: 'Kwasio (Cameroon)',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ky-KG',
    label: 'Kyrgyz (Kyrgyzstan) [кыргызча (Кыргызстан)]',
    currencySymbol: 'сом',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "yyyy-'ж'., d-MMMM, dddd",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'quc-GT',
    label: 'Kʼicheʼ (Guatemala)',
    currencySymbol: 'Q',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'lkt-US',
    label: 'Lakota (United States) [Lakȟólʼiyapi (Mílahaŋska Tȟamákȟočhe)]',
    currencySymbol: '$',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'lag-TZ',
    label: 'Langi (Tanzania) [Kɨlaangi (Taansanía)]',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'lo-LA',
    label: 'Lao (Laos) [ລາວ (ລາວ)]',
    currencySymbol: '₭',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: 'dddd ທີ d MMMM g yyyy',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'lv-LV',
    label: 'Latvian (Latvia) [latviešu (Latvija)]',
    currencySymbol: '€',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, yyyy. 'gada' d. MMMM",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ln-AO',
    label: 'Lingala (Angola) [lingála (Angóla)]',
    currencySymbol: 'Kz',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ln-CF',
    label: 'Lingala (Central African Republic) [lingála (Repibiki ya Afríka ya Káti)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ln-CD',
    label: 'Lingala (Congo [DRC]) [lingála (Republíki ya Kongó Demokratíki)]',
    currencySymbol: 'FC',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ln-CG',
    label: 'Lingala (Congo) [lingála (Kongo)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'lt-LT',
    label: 'Lithuanian (Lithuania) [lietuvių (Lietuva)]',
    currencySymbol: '€',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: "yyyy 'm'. MMMM d 'd'., dddd",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nds-DE',
    label: 'Low German (Germany)',
    currencySymbol: '€',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nds-NL',
    label: 'Low German (Netherlands)',
    currencySymbol: '€',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'dsb-DE',
    label: 'Lower Sorbian (Germany) [dolnoserbšćina (Nimska)]',
    currencySymbol: '€',
    shortDatePattern: 'd.M.yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'lu-CD',
    label: 'Luba-Katanga (Congo [DRC]) [Tshiluba (Ditunga wa Kongu)]',
    currencySymbol: 'FC',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'smj-NO',
    label: 'Lule Sami (Norway)',
    currencySymbol: 'NOK',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'smj-SE',
    label: 'Lule Sami (Sweden)',
    currencySymbol: 'SEK',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'luo-KE',
    label: 'Luo (Kenya) [Dholuo (Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'lb-LU',
    label: 'Luxembourgish (Luxembourg) [Lëtzebuergesch (Lëtzebuerg)]',
    currencySymbol: '€',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'luy-KE',
    label: 'Luyia (Kenya) [Luluhia (Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mk-MK',
    label: 'Macedonian (North Macedonia) [македонски (Северна Македонија)]',
    currencySymbol: 'ден',
    shortDatePattern: 'dd.M.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'jmc-TZ',
    label: 'Machame (Tanzania) [Kimachame (Tanzania)]',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mgh-MZ',
    label: 'Makhuwa-Meetto (Mozambique) [Makua (Umozambiki)]',
    currencySymbol: 'MTn',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'kde-TZ',
    label: 'Makonde (Tanzania) [Chimakonde (Tanzania)]',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mg-MG',
    label: 'Malagasy (Madagascar) [Malagasy (Madagasikara)]',
    currencySymbol: 'Ar',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ms-BN',
    label: 'Malay (Brunei) [Melayu (Brunei)]',
    currencySymbol: '$',
    shortDatePattern: 'd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dd MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ms-MY',
    label: 'Malay (Malaysia) [Melayu (Malaysia)]',
    currencySymbol: 'RM',
    shortDatePattern: 'd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ms-SG',
    label: 'Malay (Singapore) [Melayu (Singapura)]',
    currencySymbol: '$',
    shortDatePattern: 'd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ml-IN',
    label: 'Malayalam (India) [മലയാളം (ഇന്ത്യ)]',
    currencySymbol: '₹',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy, MMMM d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'mt-MT',
    label: 'Maltese (Malta) [Malti (Malta)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'ta'’ MMMM yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'gv-IM',
    label: 'Manx (Isle of Man) [Gaelg (Ellan Vannin)]',
    currencySymbol: '£',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mi-NZ',
    label: 'Maori (New Zealand) [Māori (Aotearoa)]',
    currencySymbol: '$',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'h:mm:ss',
    supported: false,
  },
  {
    code: 'arn-CL',
    label: 'Mapuche (Chile)',
    currencySymbol: 'CLP',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mr-IN',
    label: 'Marathi (India) [मराठी (भारत)]',
    currencySymbol: '₹',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'mas-KE',
    label: 'Masai (Kenya) [Maa (Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mas-TZ',
    label: 'Masai (Tanzania) [Maa (Tansania)]',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mzn-IR',
    label: 'Mazanderani (Iran) [‎مازرونی (ایران)‏]',
    currencySymbol: 'IRR',
    shortDatePattern: 'g yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'g yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mer-KE',
    label: 'Meru (Kenya) [Kĩmĩrũ (Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mgo-CM',
    label: 'Metaʼ (Cameroon) [metaʼ (Kamalun)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, yyyy MMMM dd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'moh-CA',
    label: 'Mohawk (Canada)',
    currencySymbol: 'CA$',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mn-MN',
    label: 'Mongolian (Mongolia) [монгол (Монгол)]',
    currencySymbol: '₮',
    shortDatePattern: 'yyyy.MM.dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy.MM.dd, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mn-Mong-CN',
    label: 'Mongolian (Mongolian, China)',
    currencySymbol: 'CN¥',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mn-Mong-MN',
    label: 'Mongolian (Mongolian, Mongolia) [ᠮᠣᠩᠭᠣᠯ (ᠮᠣᠩᠭᠣᠯ ᠪᠢᠴᠢᠭ᠌, ᠮᠣᠩᠭᠣᠯ)]',
    currencySymbol: '₮',
    shortDatePattern: 'yyyy.MM.dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy ᠣᠨ ᠎᠎᠎ᠤ MMMM᠎᠎ᠢᠢᠨd. dddd ᠋ᠭᠠᠷᠠᠭ',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mfe-MU',
    label: 'Morisyen (Mauritius) [kreol morisien (Moris)]',
    currencySymbol: 'Rs',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'mua-CM',
    label: 'Mundang (Cameroon) [MUNDAŊ (kameruŋ)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nqo-GN',
    label: 'N’Ko (Guinea)',
    currencySymbol: 'GNF',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'naq-NA',
    label: 'Nama (Namibia) [Khoekhoegowab (Namibiab)]',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ne-IN',
    label: 'Nepali (India) [नेपाली (भारत)]',
    currencySymbol: '₹',
    shortDatePattern: 'yyyy/M/d',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ne-NP',
    label: 'Nepali (Nepal) [नेपाली (नेपाल)]',
    currencySymbol: 'नेरू',
    shortDatePattern: 'yyyy/M/d',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nnh-CM',
    label: 'Ngiemboon (Cameroon) [Shwóŋò ngiembɔɔn (Kàmalûm)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd , 'lyɛ'̌ʼ d 'na' MMMM, yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'jgo-CM',
    label: 'Ngomba (Cameroon) [Ndaꞌa (Kamɛlûn)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, yyyy MMMM dd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nd-ZW',
    label: 'North Ndebele (Zimbabwe) [isiNdebele (Zimbabwe)]',
    currencySymbol: 'US$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'lrc-IR',
    label: 'Northern Luri (Iran)',
    currencySymbol: 'IRR',
    shortDatePattern: 'g yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'g yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'lrc-IQ',
    label: 'Northern Luri (Iraq)',
    currencySymbol: 'د.ع.‏',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'se-FI',
    label: 'Northern Sami (Finland) [davvisámegiella (Suopma)]',
    currencySymbol: '€',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'se-NO',
    label: 'Northern Sami (Norway) [davvisámegiella (Norga)]',
    currencySymbol: 'kr',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'se-SE',
    label: 'Northern Sami (Sweden) [davvisámegiella (Ruoŧŧa)]',
    currencySymbol: 'kr',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nb-NO',
    label: 'Norwegian Bokmål (Norway) [norsk bokmål (Norge)]',
    currencySymbol: 'kr',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nb-SJ',
    label: 'Norwegian Bokmål (Svalbard & Jan Mayen) [norsk bokmål (Svalbard og Jan Mayen)]',
    currencySymbol: 'kr',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nn-NO',
    label: 'Norwegian Nynorsk (Norway) [nynorsk (Noreg)]',
    currencySymbol: 'kr',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nus-SS',
    label: 'Nuer (South Sudan)',
    currencySymbol: '£',
    shortDatePattern: 'd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'nyn-UG',
    label: 'Nyankole (Uganda) [Runyankore (Uganda)]',
    currencySymbol: 'USh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'oc-FR',
    label: 'Occitan (France)',
    currencySymbol: '€',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'or-IN',
    label: 'Odia (India) [ଓଡ଼ିଆ (ଭାରତ)]',
    currencySymbol: '₹',
    shortDatePattern: 'M/d/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'om-ET',
    label: 'Oromo (Ethiopia) [Oromoo (Itoophiyaa)]',
    currencySymbol: 'Br',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'om-KE',
    label: 'Oromo (Kenya) [Oromoo (Keeniyaa)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, MMMM d, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'os-GE',
    label: 'Ossetic (Georgia) [ирон (Гуырдзыстон)]',
    currencySymbol: '₾',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d MMMM, yyyy 'аз'",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'os-RU',
    label: 'Ossetic (Russia) [ирон (Уӕрӕсе)]',
    currencySymbol: '₽',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d MMMM, yyyy 'аз'",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ps-AF',
    label: 'Pashto (Afghanistan) [‎پښتو (افغانستان)‏]',
    currencySymbol: '؋',
    shortDatePattern: 'g yyyy/M/d',
    shortTimePattern: 'H:mm',
    longDatePattern: 'dddd د g yyyy د MMMM d',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'ps-PK',
    label: 'Pashto (Pakistan) [‎پښتو (پاکستان)‏]',
    currencySymbol: 'Rs',
    shortDatePattern: 'yyyy/M/d',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd د yyyy د MMMM d',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'fa-AF',
    label: 'Persian (Afghanistan) [‎فارسی (افغانستان)‏]',
    currencySymbol: '؋',
    shortDatePattern: 'yyyy/M/d',
    shortTimePattern: 'H:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'fa-IR',
    label: 'Persian (Iran) [‎فارسی (ایران)‏]',
    currencySymbol: 'ریال',
    shortDatePattern: 'yyyy/M/d',
    shortTimePattern: 'H:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'pl-PL',
    label: 'Polish (Poland) [polski (Polska)]',
    currencySymbol: 'zł',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pt-AO',
    label: 'Portuguese (Angola) [português (Angola)]',
    currencySymbol: 'Kz',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pt-BR',
    label: 'Portuguese (Brazil) [português (Brasil)]',
    currencySymbol: 'R$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pt-CV',
    label: 'Portuguese (Cabo Verde) [português (Cabo Verde)]',
    currencySymbol: '​',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pt-GQ',
    label: 'Portuguese (Equatorial Guinea) [português (Guiné Equatorial)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pt-GW',
    label: 'Portuguese (Guinea-Bissau) [português (Guiné-Bissau)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pt-LU',
    label: 'Portuguese (Luxembourg) [português (Luxemburgo)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pt-MO',
    label: 'Portuguese (Macao SAR) [português (RAE de Macau)]',
    currencySymbol: 'MOP$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'pt-MZ',
    label: 'Portuguese (Mozambique) [português (Moçambique)]',
    currencySymbol: 'MTn',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pt-PT',
    label: 'Portuguese (Portugal) [português (Portugal)]',
    currencySymbol: '€',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pt-ST',
    label: 'Portuguese (São Tomé & Príncipe) [português (São Tomé e Príncipe)]',
    currencySymbol: 'Db',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pt-CH',
    label: 'Portuguese (Switzerland) [português (Suíça)]',
    currencySymbol: 'CHF',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pt-TL',
    label: 'Portuguese (Timor-Leste) [português (Timor-Leste)]',
    currencySymbol: 'US$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'pa-Arab-PK',
    label: 'Punjabi (Arabic, Pakistan) [‎پنجابی (عربی, پاکستان)‏]',
    currencySymbol: 'ر',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, dd MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'pa-Guru-IN',
    label: 'Punjabi (Gurmukhi, India) [ਪੰਜਾਬੀ (ਗੁਰਮੁਖੀ, ਭਾਰਤ)]',
    currencySymbol: '₹',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'qu-BO',
    label: 'Quechua (Bolivia) [Runasimi (Bolivia)]',
    currencySymbol: 'Bs',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'qu-EC',
    label: 'Quechua (Ecuador) [Runasimi (Ecuador)]',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'qu-PE',
    label: 'Quechua (Peru) [Runasimi (Perú)]',
    currencySymbol: 'S/',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ro-MD',
    label: 'Romanian (Moldova) [română (Republica Moldova)]',
    currencySymbol: 'L',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ro-RO',
    label: 'Romanian (Romania) [română (România)]',
    currencySymbol: 'RON',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'rm-CH',
    label: 'Romansh (Switzerland) [rumantsch (Svizra)]',
    currencySymbol: 'CHF',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, 'ils' d 'da' MMMM yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'rof-TZ',
    label: 'Rombo (Tanzania) [Kihorombo (Tanzania)]',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'rn-BI',
    label: 'Rundi (Burundi) [Ikirundi (Uburundi)]',
    currencySymbol: 'FBu',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ru-BY',
    label: 'Russian (Belarus) [русский (Беларусь)]',
    currencySymbol: 'Br',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d MMMM yyyy 'г'.",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ru-KZ',
    label: 'Russian (Kazakhstan) [русский (Казахстан)]',
    currencySymbol: '₸',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d MMMM yyyy 'г'.",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ru-KG',
    label: 'Russian (Kyrgyzstan) [русский (Киргизия)]',
    currencySymbol: 'сом',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d MMMM yyyy 'г'.",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ru-MD',
    label: 'Russian (Moldova) [русский (Молдова)]',
    currencySymbol: 'L',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d MMMM yyyy 'г'.",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ru-RU',
    label: 'Russian (Russia) [русский (Россия)]',
    currencySymbol: '₽',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d MMMM yyyy 'г'.",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ru-UA',
    label: 'Russian (Ukraine) [русский (Украина)]',
    currencySymbol: '₴',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d MMMM yyyy 'г'.",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'rwk-TZ',
    label: 'Rwa (Tanzania) [Kiruwa (Tanzania)]',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ssy-ER',
    label: 'Saho (Eritrea)',
    currencySymbol: 'Nfk',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sah-RU',
    label: 'Sakha (Russia) [саха тыла (Арассыыйа)]',
    currencySymbol: '₽',
    shortDatePattern: 'yyyy/M/d',
    shortTimePattern: 'HH:mm',
    longDatePattern: "yyyy 'сыл' MMMM d 'күнэ', dddd",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'saq-KE',
    label: 'Samburu (Kenya) [Kisampur (Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sg-CF',
    label: 'Sango (Central African Republic) [Sängö (Ködörösêse tî Bêafrîka)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sbp-TZ',
    label: 'Sangu (Tanzania) [Ishisangu (Tansaniya)]',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sa-IN',
    label: 'Sanskrit (India)',
    currencySymbol: 'रु',
    shortDatePattern: 'd-MM-yyyy',
    shortTimePattern: 'hh:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'hh:mm:ss tt',
    supported: false,
  },
  {
    code: 'gd-GB',
    label: 'Scottish Gaelic (United Kingdom) [Gàidhlig (An Rìoghachd Aonaichte)]',
    currencySymbol: '£',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d'mh' MMMM yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'seh-MZ',
    label: 'Sena (Mozambique) [sena (Moçambique)]',
    currencySymbol: 'MTn',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sr-Cyrl-BA',
    label: 'Serbian (Cyrillic, Bosnia & Herzegovina) [српски (ћирилица, Босна и Херцеговина)]',
    currencySymbol: 'КМ',
    shortDatePattern: 'd.M.yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sr-Cyrl-XK',
    label: 'Serbian (Cyrillic, Kosovo) [српски (ћирилица, Косово)]',
    currencySymbol: '€',
    shortDatePattern: 'd.M.yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sr-Cyrl-ME',
    label: 'Serbian (Cyrillic, Montenegro) [српски (ћирилица, Црна Гора)]',
    currencySymbol: '€',
    shortDatePattern: 'd.M.yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sr-Cyrl-RS',
    label: 'Serbian (Cyrillic, Serbia) [српски (ћирилица, Србија)]',
    currencySymbol: 'RSD',
    shortDatePattern: 'd.M.yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sr-Latn-BA',
    label: 'Serbian (Latin, Bosnia & Herzegovina) [srpski (latinica, Bosna i Hercegovina)]',
    currencySymbol: 'KM',
    shortDatePattern: 'd.M.yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sr-Latn-XK',
    label: 'Serbian (Latin, Kosovo) [srpski (latinica, Kosovo)]',
    currencySymbol: '€',
    shortDatePattern: 'd.M.yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sr-Latn-ME',
    label: 'Serbian (Latin, Montenegro) [srpski (latinica, Crna Gora)]',
    currencySymbol: '€',
    shortDatePattern: 'd.M.yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sr-Latn-RS',
    label: 'Serbian (Latin, Serbia) [srpski (latinica, Srbija)]',
    currencySymbol: 'RSD',
    shortDatePattern: 'd.M.yyyy.',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd. MMMM yyyy.',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'st-LS',
    label: 'Sesotho (Lesotho)',
    currencySymbol: 'R',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'st-ZA',
    label: 'Sesotho (South Africa)',
    currencySymbol: 'R',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'nso-ZA',
    label: 'Sesotho sa Leboa (South Africa)',
    currencySymbol: 'R',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'tn-BW',
    label: 'Setswana (Botswana)',
    currencySymbol: 'P',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'tn-ZA',
    label: 'Setswana (South Africa)',
    currencySymbol: 'R',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ksb-TZ',
    label: 'Shambala (Tanzania) [Kishambaa (Tanzania)]',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sn-ZW',
    label: 'Shona (Zimbabwe) [chiShona (Zimbabwe)]',
    currencySymbol: 'US$',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sd-PK',
    label: 'Sindhi (Pakistan) [‎سنڌي (پاڪستان)‏]',
    currencySymbol: 'Rs',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'si-LK',
    label: 'Sinhala (Sri Lanka) [සිංහල (ශ්‍රී ලංකාව)]',
    currencySymbol: 'රු.',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH.mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH.mm.ss',
    supported: false,
  },
  {
    code: 'ss-SZ',
    label: 'siSwati (Eswatini)',
    currencySymbol: 'E',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ss-ZA',
    label: 'siSwati (South Africa)',
    currencySymbol: 'R',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sms-FI',
    label: 'Skolt Sami (Finland)',
    currencySymbol: '€',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sk-SK',
    label: 'Slovak (Slovakia) [slovenčina (Slovensko)]',
    currencySymbol: '€',
    shortDatePattern: 'd. M. yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: 'dddd d. MMMM yyyy',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'sl-SI',
    label: 'Slovenian (Slovenia) [slovenščina (Slovenija)]',
    currencySymbol: '€',
    shortDatePattern: 'd. MM. yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'xog-UG',
    label: 'Soga (Uganda) [Olusoga (Yuganda)]',
    currencySymbol: 'USh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'so-DJ',
    label: 'Somali (Djibouti) [Soomaali (Jabuuti)]',
    currencySymbol: 'Fdj',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM dd, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'so-ET',
    label: 'Somali (Ethiopia) [Soomaali (Itoobiya)]',
    currencySymbol: 'Br',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM dd, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'so-KE',
    label: 'Somali (Kenya) [Soomaali (Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, MMMM dd, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'so-SO',
    label: 'Somali (Somalia) [Soomaali (Soomaaliya)]',
    currencySymbol: 'S',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, MMMM dd, yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'nr-ZA',
    label: 'South Ndebele (South Africa)',
    currencySymbol: 'R',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sma-NO',
    label: 'Southern Sami (Norway)',
    currencySymbol: 'NOK',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sma-SE',
    label: 'Southern Sami (Sweden)',
    currencySymbol: 'SEK',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-AR',
    label: 'Spanish (Argentina) [español (Argentina)]',
    currencySymbol: '$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-BZ',
    label: 'Spanish (Belize) [español (Belice)]',
    currencySymbol: '$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-BO',
    label: 'Spanish (Bolivia) [español (Bolivia)]',
    currencySymbol: 'Bs',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-BR',
    label: 'Spanish (Brazil) [español (Brasil)]',
    currencySymbol: 'R$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-CL',
    label: 'Spanish (Chile) [español (Chile)]',
    currencySymbol: '$',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-CO',
    label: 'Spanish (Colombia) [español (Colombia)]',
    currencySymbol: '$',
    shortDatePattern: 'd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'es-CR',
    label: 'Spanish (Costa Rica) [español (Costa Rica)]',
    currencySymbol: '₡',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-CU',
    label: 'Spanish (Cuba) [español (Cuba)]',
    currencySymbol: '$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-DO',
    label: 'Spanish (Dominican Republic) [español (República Dominicana)]',
    currencySymbol: 'RD$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'es-EC',
    label: 'Spanish (Ecuador) [español (Ecuador)]',
    currencySymbol: '$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-SV',
    label: 'Spanish (El Salvador) [español (El Salvador)]',
    currencySymbol: '$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-GQ',
    label: 'Spanish (Equatorial Guinea) [español (Guinea Ecuatorial)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'es-GT',
    label: 'Spanish (Guatemala) [español (Guatemala)]',
    currencySymbol: 'Q',
    shortDatePattern: 'd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-HN',
    label: 'Spanish (Honduras) [español (Honduras)]',
    currencySymbol: 'L',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd dd 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-MX',
    label: 'Spanish (Mexico) [español (México)]',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'es-NI',
    label: 'Spanish (Nicaragua) [español (Nicaragua)]',
    currencySymbol: 'C$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-PA',
    label: 'Spanish (Panama) [español (Panamá)]',
    currencySymbol: 'B/.',
    shortDatePattern: 'MM/dd/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'es-PY',
    label: 'Spanish (Paraguay) [español (Paraguay)]',
    currencySymbol: 'Gs.',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-PE',
    label: 'Spanish (Peru) [español (Perú)]',
    currencySymbol: 'S/',
    shortDatePattern: 'd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-PH',
    label: 'Spanish (Philippines) [español (Filipinas)]',
    currencySymbol: '₱',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'es-PR',
    label: 'Spanish (Puerto Rico) [español (Puerto Rico)]',
    currencySymbol: '$',
    shortDatePattern: 'MM/dd/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'es-ES',
    label: 'Spanish (Spain) [español (España)]',
    currencySymbol: '€',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'es-US',
    label: 'Spanish (United States) [español (Estados Unidos)]',
    currencySymbol: '$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'es-UY',
    label: 'Spanish (Uruguay) [español (Uruguay)]',
    currencySymbol: '$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'es-VE',
    label: 'Spanish (Venezuela) [español (Venezuela)]',
    currencySymbol: 'Bs.S',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: "dddd, d 'de' MMMM 'de' yyyy",
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'zgh-MA',
    label: 'Standard Moroccan Tamazight (Morocco) [ⵜⴰⵎⴰⵣⵉⵖⵜ (ⵍⵎⵖⵔⵉⴱ)]',
    currencySymbol: 'MAD',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sv-AX',
    label: 'Swedish (Åland Islands) [svenska (Åland)]',
    currencySymbol: '€',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sv-FI',
    label: 'Swedish (Finland) [svenska (Finland)]',
    currencySymbol: '€',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'sv-SE',
    label: 'Swedish (Sweden) [svenska (Sverige)]',
    currencySymbol: 'kr',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'gsw-FR',
    label: 'Swiss German (France) [Elsässisch (Frànkrisch)]',
    currencySymbol: '€',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'gsw-LI',
    label: 'Swiss German (Liechtenstein) [Schwiizertüütsch (Liächteschtäi)]',
    currencySymbol: 'CHF',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'gsw-CH',
    label: 'Swiss German (Switzerland) [Schwiizertüütsch (Schwiiz)]',
    currencySymbol: 'CHF',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'syr-SY',
    label: 'Syriac (Syria)',
    currencySymbol: 'SYP',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'shi-Latn-MA',
    label: 'Tachelhit (Latin, Morocco)',
    currencySymbol: 'MAD',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'shi-Tfng-MA',
    label: 'Tachelhit (Tifinagh, Morocco)',
    currencySymbol: 'MAD',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'dav-KE',
    label: 'Taita (Kenya) [Kitaita (Kenya)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'tg-TJ',
    label: 'Tajik (Tajikistan) [тоҷикӣ (Тоҷикистон)]',
    currencySymbol: 'сом.',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ta-IN',
    label: 'Tamil (India) [தமிழ் (இந்தியா)]',
    currencySymbol: '₹',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'tt h:mm',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'tt h:mm:ss',
    supported: false,
  },
  {
    code: 'ta-MY',
    label: 'Tamil (Malaysia) [தமிழ் (மலேசியா)]',
    currencySymbol: 'RM',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'tt h:mm',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'tt h:mm:ss',
    supported: false,
  },
  {
    code: 'ta-SG',
    label: 'Tamil (Singapore) [தமிழ் (சிங்கப்பூர்)]',
    currencySymbol: '$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'tt h:mm',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'tt h:mm:ss',
    supported: false,
  },
  {
    code: 'ta-LK',
    label: 'Tamil (Sri Lanka) [தமிழ் (இலங்கை)]',
    currencySymbol: 'Rs.',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'twq-NE',
    label: 'Tasawaq (Niger) [Tasawaq senni (Nižer)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'tt-RU',
    label: 'Tatar (Russia) [татар (Россия)]',
    currencySymbol: '₽',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'H:mm',
    longDatePattern: "d MMMM, yyyy 'ел', dddd",
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'te-IN',
    label: 'Telugu (India) [తెలుగు (భారతదేశం)]',
    currencySymbol: '₹',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'd, MMMM yyyy, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'teo-KE',
    label: 'Teso (Kenya) [Kiteso (Kenia)]',
    currencySymbol: 'Ksh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'teo-UG',
    label: 'Teso (Uganda) [Kiteso (Uganda)]',
    currencySymbol: 'USh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'th-TH',
    label: 'Thai (Thailand) [ไทย (ไทย)]',
    currencySymbol: '฿',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'ddddที่ d MMMM g yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'bo-CN',
    label: 'Tibetan (China) [བོད་སྐད་ (རྒྱ་ནག)]',
    currencySymbol: '¥',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy MMMMའི་ཚེས་d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'bo-IN',
    label: 'Tibetan (India) [བོད་སྐད་ (རྒྱ་གར་)]',
    currencySymbol: '₹',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy MMMMའི་ཚེས་d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'tig-ER',
    label: 'Tigre (Eritrea)',
    currencySymbol: 'Nfk',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ti-ER',
    label: 'Tigrinya (Eritrea) [ትግርኛ (ኤርትራ)]',
    currencySymbol: 'Nfk',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd፣ dd MMMM መዓልቲ yyyy g',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ti-ET',
    label: 'Tigrinya (Ethiopia) [ትግርኛ (ኢትዮጵያ)]',
    currencySymbol: 'Br',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd፣ dd MMMM መዓልቲ yyyy g',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'to-TO',
    label: 'Tongan (Tonga) [lea fakatonga (Tonga)]',
    currencySymbol: 'T$',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'tr-CY',
    label: 'Turkish (Cyprus) [Türkçe (Kıbrıs)]',
    currencySymbol: '€',
    shortDatePattern: 'd.MM.yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'd MMMM yyyy dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'tr-TR',
    label: 'Turkish (Turkey) [Türkçe (Türkiye)]',
    currencySymbol: '₺',
    shortDatePattern: 'd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'd MMMM yyyy dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'tk-TM',
    label: 'Turkmen (Turkmenistan) [türkmen dili (Türkmenistan)]',
    currencySymbol: 'TMT',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'd MMMM yyyy dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'uk-UA',
    label: 'Ukrainian (Ukraine) [українська (Україна)]',
    currencySymbol: '₴',
    shortDatePattern: 'dd.MM.yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: "dddd, d MMMM yyyy 'р'.",
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'hsb-DE',
    label: 'Upper Sorbian (Germany) [hornjoserbšćina (Němska)]',
    currencySymbol: '€',
    shortDatePattern: 'd.M.yyyy',
    shortTimePattern: "H:mm 'hodź'.",
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'H:mm:ss',
    supported: false,
  },
  {
    code: 'ur-IN',
    label: 'Urdu (India) [‎اردو (بھارت)‏]',
    currencySymbol: '₹',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM، yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ur-PK',
    label: 'Urdu (Pakistan) [‎اردو (پاکستان)‏]',
    currencySymbol: 'Rs',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd، d MMMM، yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'ug-CN',
    label: 'Uyghur (China) [‎ئۇيغۇرچە (جۇڭگو)‏]',
    currencySymbol: '￥',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy d-MMMM، dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'uz-Arab-AF',
    label: 'Uzbek (Arabic, Afghanistan) [‎اوزبیک (عربی, افغانستان)‏]',
    currencySymbol: '؋',
    shortDatePattern: 'g yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'g yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'uz-Cyrl-UZ',
    label: 'Uzbek (Cyrillic, Uzbekistan) [ўзбекча (Кирил, Ўзбекистон)]',
    currencySymbol: 'сўм',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, dd MMMM, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'uz-Latn-UZ',
    label: 'Uzbek (Latin, Uzbekistan) [o‘zbek (lotin, Oʻzbekiston)]',
    currencySymbol: 'soʻm',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d-MMMM, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'vai-Latn-LR',
    label: 'Vai (Latin, Liberia)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'vai-Vaii-LR',
    label: 'Vai (Vai, Liberia)',
    currencySymbol: '$',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 've-ZA',
    label: 'Venda (South Africa)',
    currencySymbol: 'R',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'vi-VN',
    label: 'Vietnamese (Vietnam) [Tiếng Việt (Việt Nam)]',
    currencySymbol: '₫',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'vun-TZ',
    label: 'Vunjo (Tanzania) [Kyivunjo (Tanzania)]',
    currencySymbol: 'TSh',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'wae-CH',
    label: 'Walser (Switzerland) [Walser (Schwiz)]',
    currencySymbol: 'CHF',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d. MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'cy-GB',
    label: 'Welsh (United Kingdom) [Cymraeg (Y Deyrnas Unedig)]',
    currencySymbol: '£',
    shortDatePattern: 'dd/MM/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'fy-NL',
    label: 'Western Frisian (Netherlands) [Frysk (Nederlân)]',
    currencySymbol: '€',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'wal-ET',
    label: 'Wolaytta (Ethiopia)',
    currencySymbol: 'Br',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'wo-SN',
    label: 'Wolof (Senegal) [Wolof (Senegaal)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'dd-MM-yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd, d MMM, yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ts-ZA',
    label: 'Xitsonga (South Africa)',
    currencySymbol: 'R',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'yav-CM',
    label: 'Yangben (Cameroon) [nuasue (Kemelún)]',
    currencySymbol: 'FCFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
  {
    code: 'ii-CN',
    label: 'Yi (China) [ꆈꌠꉙ (ꍏꇩ)]',
    currencySymbol: '¥',
    shortDatePattern: 'yyyy-MM-dd',
    shortTimePattern: 'h:mm tt',
    longDatePattern: 'yyyy MMMM d, dddd',
    longTimePattern: 'h:mm:ss tt',
    supported: false,
  },
  {
    code: 'yo-BJ',
    label: 'Yoruba (Benin) [Èdè Yorùbá (Orílɛ́ède Bɛ̀nɛ̀)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'H:m',
    longDatePattern: 'dddd, d MMM yyyy',
    longTimePattern: 'H:m:s',
    supported: false,
  },
  {
    code: 'yo-NG',
    label: 'Yoruba (Nigeria) [Èdè Yorùbá (Orilẹ̀-èdè Nàìjíríà)]',
    currencySymbol: '₦',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'H:m',
    longDatePattern: 'dddd, d MMM yyyy',
    longTimePattern: 'H:m:s',
    supported: false,
  },
  {
    code: 'dje-NE',
    label: 'Zarma (Niger) [Zarmaciine (Nižer)]',
    currencySymbol: 'CFA',
    shortDatePattern: 'd/M/yyyy',
    shortTimePattern: 'HH:mm',
    longDatePattern: 'dddd d MMMM yyyy',
    longTimePattern: 'HH:mm:ss',
    supported: false,
  },
];
