import {
  ADD_TEMPLATE,
  GET_TEMPLATE,
  EDIT_TEMPLATE
} from '../constants';

const INIT_STATE = {
  data: []
};

const templateReducer = (state = INIT_STATE, action) => {
  let returnObj = state;
  if (action.type === ADD_TEMPLATE) {
    localStorage.setItem('templateData', JSON.stringify(action.payload))
    returnObj = {
      data: action.payload
    };
  }
  else if(action.type === GET_TEMPLATE){
    const templateData = JSON.parse(localStorage.getItem('templateData'));
    return templateData || state;
  }
  else if (action.type === EDIT_TEMPLATE) {
    localStorage.setItem('templateData', JSON.stringify(action.payload))
    returnObj = {
      data: action.payload
    };
  }
  return returnObj;
}

export default templateReducer;
