export default {
    onUpdate: registration => {
      window.location.reload();
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      registration?.unregister().then(() => {
        window.location.reload()
      })
    },
    onSuccess: () => {
      console.info('service worker on success state')
    },
}