import _cloneDeep from 'lodash/cloneDeep';
import { ADD_ACTIVITY, GET_ALL_ACTIVIIES, SET_ACTION_DETAILS, SET_ACTION_ASSIGNED_USERS, ADD_TO_AUDIT_HISTORY, SET_ALL_AUDIT_HISTORY } from '../constants';

const INIT_STATE = {
  actionDetails: null,
  assignedUsers: null,
  allAuditHistory: []
};

const ChatReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ALL_ACTIVIIES:
      return {
        ...state
      };

    case SET_ACTION_DETAILS: {
      return {
        ...state,
        actionDetails: action.payload,
        allAuditHistory: []
      };
    }
    
    case SET_ALL_AUDIT_HISTORY: {
      return {
        ...state,
        allAuditHistory: action.payload ? [...action.payload] : []
      };
    }

    case ADD_ACTIVITY: {
      const data = _cloneDeep(state.actionDetails);
      if (action.payload) {
        data._version = action?.payload?.version;
        if (Array.isArray(action?.payload?.newItem)) {
          data.actionAuditHistory.push(...action?.payload?.newItem)          
        } else {
          data.actionAuditHistory.push(action?.payload?.newItem)
        }
      }
      return {
        ...state,
        actionDetails: { ...data, ...action?.payload?.otherDatas },
      }
    }

    case ADD_TO_AUDIT_HISTORY: {
      const auditData = _cloneDeep(state.allAuditHistory);
      if (action.payload) {
        if (Array.isArray(action?.payload?.newItem)) {
          auditData.push(...action?.payload?.newItem)
        } else {
          auditData.push(action?.payload?.newItem)
        }
      }
      return {
        ...state,
        allAuditHistory: [...auditData]
      }
    }

    case SET_ACTION_ASSIGNED_USERS: {
      return {
        ...state,
        assignedUsers: action.payload
      }
    }

    default:
      return state;
  }
};

export default ChatReducer;
