import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { LANGUAGES } from 'shared/languages';
import LanguageDetector from 'i18next-browser-languagedetector';
import { setLocale } from 'yup';

const defaultLng = LANGUAGES.find((singleLng) => singleLng?.default)?.code || 'en-AU';

const INIT_OPTIONS = {
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'navigator'],

    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
  },
  backend: {
    /* translation file path */
    loadPath: '/i18n/{{ns}}/{{lng}}.json',
  },
  fallbackLng: defaultLng,
  lng: defaultLng,
  debug: false,
  ns: ['translations', 'common'],
  defaultNS: 'translations',
  supportedLngs: LANGUAGES.filter(({ supported }) => supported).map(({ code }) => code),
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    // wait: true, => depricated
    useSuspense: true,
  },
};

i18n.use(LanguageDetector).use(Backend).use(initReactI18next).init(INIT_OPTIONS);

i18n.on('languageChanged', () => {
  const locale = {
    mixed: {
      default: i18n.t('common:yup.mixed.default'),
      required: i18n.t('common:yup.mixed.required'),
      oneOf: i18n.t('common:yup.mixed.oneOf'),
      notOneOf: i18n.t('common:yup.mixed.notOneOf'),
      notType: i18n.t('common:yup.mixed.notType'),
      defined: i18n.t('common:yup.mixed.defined'),
    },
    string: {
      length: i18n.t('common:yup.string.length'),
      min: i18n.t('common:yup.string.min'),
      max: i18n.t('common:yup.string.max'),
      matches: i18n.t('common:yup.string.matches'),
      email: i18n.t('common:yup.string.email'),
      url: i18n.t('common:yup.string.url'),
      uuid: i18n.t('common:yup.string.uuid'),
      trim: i18n.t('common:yup.string.trim'),
      lowercase: i18n.t('common:yup.string.lowercase'),
      uppercase: i18n.t('common:yup.string.uppercase'),
    },
    number: {
      min: i18n.t('common:yup.number.min'),
      max: i18n.t('common:yup.number.max'),
      lessThan: i18n.t('common:yup.number.lessThan'),
      moreThan: i18n.t('common:yup.number.moreThan'),
      positive: i18n.t('common:yup.number.positive'),
      negative: i18n.t('common:yup.number.negative'),
      integer: i18n.t('common:yup.number.integer'),
    },
    date: {
      min: i18n.t('common:yup.date.min'),
      max: i18n.t('common:yup.date.max'),
    },
    object: {
      noUnknown: i18n.t('common:yup.object.noUnknown'),
    },
    array: {
      min: i18n.t('common:yup.array.min'),
      max: i18n.t('common:yup.array.max'),
      length: i18n.t('common:yup.array.length'),
    },
    boolean: {
      isValue: i18n.t('common:yup.boolean.isValue'),
    },
  };
  setLocale(locale);
});

export default i18n;
