import React from 'react';
import Box from '@mui/material/Box';
import GlobalLoader from 'assets/images/loader/loader.gif';
import './loaders.main.scss';

const MaskLoader = () => (
  <Box className="mask-loader-wrapper">
    <img
      src={GlobalLoader}
      alt={GlobalLoader}
      loading="lazy"
    />
  </Box>
);
export default MaskLoader;
