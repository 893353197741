import React, { Suspense } from 'react';
import {Amplify, Auth, Hub} from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/Store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import MaskLoader from './components/loaders/MaskLoader';
import swConfig from './swConfig';
import awsconfig from './aws-exports';
import './i18n';

// style for whole application
import './style/common.css'

// style imports for react slick corousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App = React.lazy(() => import('./App'));

const ReactDOM = require('react-dom');

Hub.listen('auth', (data) => {
  if (data.payload.event === 'signIn_failure') {
    alert("SSO Login Failure")
  }
})

Amplify.configure({
  API: {
      graphql_headers: async () => {
          const session = await Auth.currentSession();
          return {
              Authorization: session.getIdToken().getJwtToken(),
          };
      },
  },
  ...awsconfig
})

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<MaskLoader />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);

// If you want to enable client cache, register instead.
serviceWorkerRegistration.register(swConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
